import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import agilLogo from './images/logo_topo.png';
import YoutubeEmbed from "./YoutubeEmbed"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
       Mais Ágil Franquias
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = ["IgXUO2Ftisc", "_tz3mdxjSXk", "jWVyBK-utP4", "iSTEPvCEFYU",  "zIH7dDsAGFU", "wavi23UUW68", "IWs4CHoNefE"];

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar style={{backgroundColor:"#0FF1CE"}} position="relative">
        <Toolbar>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <img
              align="center"
              style={{paddingLeft: "150px", paddingBottom: "20px"}}
            src={agilLogo}
            alt="memories"
            height="140"
          />
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            PASSO A PASSO

FORMALIZAÇÃO DIGITAL

DOS PRINCIPAIS BANCOS
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Querido Franqueado,                                                                                                                                                          Criamos essa página para te ajudar no processo de formalização digital dos bancos. Abaixo você encontra os videos elaborados pelos bancos da visão do cliente para a formalização.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <YoutubeEmbed embedId={card}/>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Para duvidas
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Entre em contato ao lado com a nossa assistente virtual Gi.
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
